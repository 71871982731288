
  import { defineComponent, ref, computed, watch, reactive, getCurrentInstance } from "vue"
  import { sellerCareStayColumns as careStayColumns } from '@/view_scripts/table_columns/care_stay_columns'
  import { CareStay } from "@/models/care_stays";
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import { careStaySchema, sellerUiCareStaySchema as uiCareStaySchema } from "@/view_scripts/form_schemas/care_stay"
  import { getRefreshData } from "@/utils/refreshData"
  import { confirm, prompt } from '@/utils/interactionModals'
  export default defineComponent({
    components: {
      SchemaForm
    },
    props: { items: { type: Array, required: true } },
    setup(props: any) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const availableStages = ['Neu', 'Vorgestellt', 'Angenommen', 'Abgelehnt', 'Abgebrochen', 'Bestätigt', 'Alle']
      const stage = ref('Alle')
      const filteredCareStays = computed(() => props.items.filter(cs => {
        return (stage.value === 'Alle' || cs.attributes.stage === stage.value)
      }))
      const editCareStayModal = ref()
      const csActionModal = ref()
      const careStayToEdit = ref<ResourceObject<string, CareStay>>()
      const refreshData = getRefreshData()
      const csActionData = reactive<{[key: string]: any}>({
        type: null,
        reasons: [],
        wrongstate: false,
        customReason: '',
        selectedReason: null,
        title: 'Default Titel',
        billEnd: null,
        departure: null,
        action: () => {alert('es wurde keine Aktion festgelegt')}
      })
      const resetCsActionData = () => {
        csActionData.reasons = []
        csActionData.wrongstate = false
        csActionData.customReason = ''
        csActionData.selectedReason = null
        csActionData.title = 'Default Titel'
        csActionData.billEnd = null
        csActionData.departure = null
      }

      const getReasons = async (sysId: string) => {
        const listQuery = {'filter[sys_id]': sysId, include: 'sublists'}
        showLoading.value = true
        await root.$store.dispatch('lists/load', listQuery).finally(() => {showLoading.value = false})
        const reasons = root.$store.state.lists.data[0].attributes.sublists
          .map((rr)=>{ return {value: rr.id, text: rr.attributes?.name} })
          .concat({value: 'new', text: "Eigenen Grund angeben..."})
        showLoading.value = false
        return reasons
      }

      const confirmMsg = async (msg: string) => {
        return await confirm(root, msg)
      }

      const editCareStay = (data: any) => {
        careStayToEdit.value = JSON.parse(JSON.stringify(data.item))
        editCareStayModal.value.show()
      }

      const acceptCareStay = async (data: any) => {
        if (!['Neu','Vorgestellt'].includes(data.item.attributes.stage)) {
          prompt(root, 'Nur Neue oder Vorgestellte Einsätze können angenommen werden.')
          return false
        }
        const msg = 'Der Vorschlag wird angenommen. \n\nBitte bei Fehlern nicht wiederholt clicken.\nErst die Seite neu laden und dann ggf. neu versuchen.\n\nACHTUNG, diese Zusage ist verbindlich!'
        if (!(await confirmMsg(msg))) { return false }

        showLoading.value = true
        root.$store.dispatch('care_stays/edit', { id: data.item.id, body: {usecase: 'cs_accept'} })
          .then(refreshData)
          .finally(() => {showLoading.value = false})
      }

      const rejectCareStay = async (data: any) => {
        if (!['Neu', 'Vorgestellt'].includes(data.item.attributes.stage)) {
          alert('Nur >Neue< oder >Vorgestellte< Einsätze können angenommen werden.')
          return false
        }
        resetCsActionData()
        csActionData.usecase = 'cs_reject'
        const reasons = await getReasons('suggestion_reject_reasons')
        reasons.unshift({value: null, text: 'Grund für die Ablehnung wählen'})
        csActionData.title = 'Vorgeschlagene BK ablehnen'
        csActionData.reasons = reasons
        csActionData.action = (careStay: any) => {
          const body = {
            attributes: data.item.attributes,
            reason: csActionData.selectedReason,
            customReason: csActionData.customReason,
            usecase: csActionData.usecase,
          }
          showLoading.value = true
          root.$store.dispatch('care_stays/edit', { id: data.item.id, body })
            .then(refreshData)
            .finally((r) => showLoading.value = false)
          root.$nextTick(() => { csActionModal.value.hide() })
        }
        csActionModal.value.show()
      }

      const cancelCareStay = async (data: any) => {
        resetCsActionData()
        csActionData.usecase = 'cs_cancel'
        const reasons = await getReasons('suggestion_cancel_reasons')
        reasons.unshift({value: null, text: 'Grund für den Abbruch wählen'})
        csActionData.title = 'Pflegeeinsatz abbrechen'
        csActionData.reasons = reasons
        csActionData.action = (careStay: any) => {
          const body = {
            attributes: data.item.attributes,
            reason: csActionData.selectedReason,
            customReason: csActionData.customReason,
            usecase: csActionData.usecase,
          }
          showLoading.value = true
          root.$store.dispatch('care_stays/edit', { id: data.item.id, body })
            .then(refreshData)
            .finally((r) => showLoading.value = false)
          root.$nextTick(() => { csActionModal.value.hide() })
        }
        csActionModal.value.show()
      }

      const careStayActionColumns = ref(careStayColumns({
        editCareStay,
        acceptCareStay,
        rejectCareStay,
        cancelCareStay,
      }))

      const saveCareStay = async (bvModalEvent: any) => {
        showLoading.value = true
        root.$store.dispatch('care_stays/edit', { id: careStayToEdit.value.id, body: careStayToEdit.value })
          .then(refreshData)
          .finally(() => {showLoading.value = false})
      }
      return {
        showLoading,
        editCareStayModal,
        csActionModal,
        careStaySchema,
        uiCareStaySchema,
        saveCareStay,
        careStayToEdit,
        careStayActionColumns,
        csActionData,
        filteredCareStays,
        stage,
        availableStages
      }
    }
  })
